// Bootstrap Framework
import './scss/index.scss'
import jquery from 'jquery'
window.$ = window.jQuery = jquery
import 'popper.js'
import 'bootstrap'

import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import 'jquery-match-height'

import '@fancyapps/fancybox'

import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'
import axios from 'axios'


$(function () {

    //#region slider
    $('.slider').slick({
        arrows: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false
    })
    //#endregion

    //#region gallery
    var grid = document.querySelector('.grid');
    if (grid) {        
        imagesLoaded(grid, () => {
            var msnry = new Masonry(grid, {
                itemSelector: '.grid-item'
            });
        })
    }
    //#endregion

    //#region socialwall
    if ($('#social-wall-intro').length || $('#social-wall').length) {

        //console.log("Initialize Social Wall");

        let facebook
        let instagram
        /* facebook */
        const api_facebook = 'https://graph.facebook.com/AnticaTorreAgriturismo/feed'
        //const page_id = '364913416978946'
        const access_token_facebook = 'EAAB9Y9LKFtwBAIL65SEPmrUkTLidYJVGnTmAvEQ8FDcPGMWRw81xZAhU4e6jhvO59GBjHOv5vQHC9tGSCgrjTLojLsLgbcARg9B9m6t5brQ164HSKHZAac27jv8d1Rt9sO9NExZBPUKG8GZALbUm2sZA1H7JvA25wqBHkJgTnXv5d8NOrlbZAetfsZA0bcZCZAPqTrKShwWjczQZDZD'
        const fields_facebook = 'id,message,embed_html,picture,icon,created_time,from,likes,comments,attachments{name,unshimmed_url,media_type,target,media{source,image}}'


        /* facebook api call */
        axios.get(api_facebook, {
            params: {
                access_token: access_token_facebook,
                fields: fields_facebook
            }
        }).then(res_facebook => {
            //console.log(res_facebook.data.data)
            facebook = res_facebook.data.data
            facebook.forEach(el => {
                el.social_type = 'facebook'
                el.social_order = new Date(el.created_time)
            })
            /* mixing in */
            let social_feed = facebook.concat(instagram)
            social_feed.sort(sortByOrder)
            domRendering(social_feed)

        }).catch(err => { })


        /* instagram */
        const instagram_business_account = '17841408282492606';
        const api_instagram = 'https://graph.facebook.com/' + instagram_business_account + '/media';
        const fields_instagram = 'id,caption,media_url,permalink,thumbnail_url,timestamp,media_type'

        /* instagram api call */
        axios.get(api_instagram, {
            params: {
                access_token: access_token_facebook,
                fields: fields_instagram
            }
        }).then(res_instagram => {
        instagram = res_instagram.data.data
        instagram.forEach(el => {
            el.social_type = 'instagram'
            el.social_order = new Date(el.timestamp)
        })

      
        }).catch(err => {})
                
        /* sort function */
        function sortByOrder(a, b) {
            if (a.social_order > b.social_order) {
                return -1;
            }
            if (b.social_order > a.social_order) {
                return 1;
            }
            return 0;
        }

        /* dom rendering function */
        function domRendering(social_feed) {
            let dom = '#social-wall';
            if ($('#social-wall-intro').length) {


                social_feed = social_feed.filter(function (el) {
                    return el.social_type == 'instagram'
                }).slice(0, 4)

                dom = '#social-wall-intro';
            }
            social_feed.forEach(el => {
                if (el.social_type == 'facebook' && !PostIsLink(el)) {
                    $(dom).append(
                        `<div class="col-xl-3 socialwall">` +
                        `<div class="socialwall__item">` +
                           RenderAttacchment(el) +
                            //    (el.picture ? 
                            //    `<a href="${el.link}" target="_blank" class="socialwall__image">` +
                            //        `<img src="${el.picture}">` +
                            //`</a>` : ``) +
                                (el.name ? 
                                `<p class="socialwall__title">${el.name}</p>` : ``) +
                                (el.description ?
                                `<p class="socialwall__description">${el.description.length > 200 ? el.description.substring(0, 200) + '...': el.description}</p>` : ``) +
                                (el.message ? 
                                `<p class="socialwall__message">${el.message.length > 200 ? el.message.substring(0, 200) + '...': el.message}</p>` : ``) +
                                `<p class="socialwall__date">${new Date(el.created_time).toLocaleDateString()}</p>` +
                                `<a class="socialwall__icon" href="https://www.facebook.com/AnticaTorreAgriturismo/" target="_blank"><img src="./../images/ico-facebook-wall.svg"></a>` +
                            `</div>` +
                        `</div>`
                    )
                }
                if (el.social_type == 'instagram') {
                    $(dom).append(
                        `<div class="col-xl-3 socialwall">` +
                            `<div class="socialwall__item">` +
                                `<a href="${el.permalink}" target="_blank" class="socialwall__image">` +
                                    (el.media_type == 'IMAGE' || el.media_type == 'CAROUSEL_ALBUM' ?
                                    `<img src="${el.media_url}">` : 
                                    el.media_type == 'VIDEO' ? 
                                    `<video poster="${el.thumbnail_url}" controls >` +
                                    `<source src="${el.media_url}" type="video/mp4">`+
                                    `</video>` : ``) +
                                `</a>` +
                                (el.caption ?
                                `<p class="socialwall__title">${el.caption}</p>` : ``) +
                                `<p class="socialwall__date">${new Date(el.timestamp).toLocaleDateString()}</p>` +
                                `<a class="socialwall__icon" href="https://www.instagram.com/agriturismoanticatorre/" target="_blank"><img src="./../images/ico-instagram-wall.svg"></a>` +
                            `</div>` +
                        `</div>`
                    )
                }
            });
            var grid
            if  ($('#social-wall-intro').length) {
                grid = document.getElementById('social-wall-intro');
            }
            else {
                grid = document.getElementById('social-wall');
            }
            imagesLoaded(grid, () => {
                var msnry = new Masonry(grid, {
                    itemSelector: '.socialwall'
                });
            })
        }


        function RenderAttacchment(el) {
            var MediaType = el.attachments && el.attachments.data && el.attachments.data[0] && el.attachments.data[0].media_type ? el.attachments.data[0].media_type : '';
          

            var Html = '';

            switch (MediaType) {

                //case 'link':
                case 'album':
                case 'photo':
                    Html = '<a href="' + el.attachments.data[0].unshimmed_url + '" target="_blank" class="socialwall__image" >' +
                        '<img src="' + el.attachments.data[0].media.image.src + '">' +
                            '</a>';

                    break;

                case 'video':
                    Html = '<a href="' + el.attachments.data[0].unshimmed_url + '" target="_blank" class="socialwall__image" >' +
                        '<img src="' + el.attachments.data[0].media.image.src + '">' +
                        '</a>';


                    //Html = 
                    //    '<a href="' + el.attachments.data[0].unshimmed_url + '" target="_blank" class="socialwall__image" >' +
                    //    '<video poster="' + el.attachments.data[0].media.image.src + '" controls class="socialwall__image" ">' +
                    //'<source src="' + el.attachments.data[0].media.image.src + '"type="video/mp4">' +
                    //    '</video> </a>';

                    break;

                //default:
                //    console.log('Current Media Type: ' + MediaType);
                //    console.log(el);
                //    break;
            }

            return Html;

     
        }
        function PostIsLink(el) {

            var IsLink = false;
            var MediaType = el.attachments && el.attachments.data && el.attachments.data[0] && el.attachments.data[0].media_type ? el.attachments.data[0].media_type : '';

            IsLink = MediaType == 'link';

            return IsLink;
        }
    }
    //#endregion

});